import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from "../environments/environment";
import {FormsModule} from "@angular/forms";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {DateDisplayPipe} from './pipe/date-display.pipe';
import {DatePipe} from "@angular/common";
import {LoginComponent} from './pages/login/login.component';
import {ChatComponent} from './pages/chat/chat.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {JwtInterceptor} from "./interceptor/jwt.interceptor";
import {AuthGuard} from "./guard/auth-guard";
import {AuthService} from "./service/auth.service";
import {NotifierModule} from "angular-notifier";
import {notifierDefaultOptions} from "../../notifier-config";
import {JWT_OPTIONS, JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {TruncateMessagePipe} from "./pipe/truncate-message.pipe";

export const jwtOptionsFactory = () => ({
  tokenGetter: () => {
    return localStorage.getItem("access_token");
  }
});

@NgModule({
  declarations: [
    AppComponent,
    DateDisplayPipe,
    TruncateMessagePipe,
    LoginComponent,
    ChatComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      }
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    AuthGuard,
    AuthService,
    JwtHelperService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
