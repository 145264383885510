import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./pages/login/login.component";
import {ChatComponent} from "./pages/chat/chat.component";
import {AuthGuard} from "./guard/auth-guard";

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  }
  ,
  {
    path: 'chat',
    component: ChatComponent,
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
