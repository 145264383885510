import {Injectable} from '@angular/core';
import {forkJoin, from, map, mergeMap, Observable} from "rxjs";
import {Chat, Location, Message, SenderType, User} from "../model/models";
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where
} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class ChatServiceService {

  chatList: Chat[] = [];

  constructor(private firestore: Firestore) {
  }

  getAllChatsWithLatestMessage(): Observable<Chat[]> {
    return new Observable((observer) => {
      const chatRef = collection(this.firestore, 'chats');

      const unsubscribe = onSnapshot(chatRef, (snapshot) => {
        this.chatList = [];
        snapshot.docChanges().forEach((change) => {
          let chat: any = change.doc.data();
          chat.id = change.doc.id;

          if (change.type === "added") {
            this.chatList.push(chat);
          }
          if (change.type === "modified") {
            const index = this.chatList.findIndex((x) => x.id === chat.id);
            this.chatList[index] = chat;
          }
          if (change.type === "removed") {
            this.chatList = this.chatList.filter((x) => x.id !== chat.id);
          }
        });
        observer.next(this.chatList);
      });


      return () => unsubscribe();
    });

  }


  async sendMessage(locationId: string, chatId: string | null, content: string, sender: User, receiver: User, location: Location): Promise<void> {
    try {
      const chatRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}`);
      const chatSnapshot = await getDoc(chatRef);
      if (!chatSnapshot.exists()) {
        const newChat: Partial<Chat> = {
          id: chatId as string,
          location: {
            id: location.locationId,
            firstName: location.locationName,
            lastName: "",
            avatar: '../assets/icons/staff.svg'
          },
          createdAt: Timestamp.now(),
          chatUser: sender,
          latestMessage: {
            content: content,
            timestamp: Timestamp.now(),
            sender: sender
          }
        };
        await setDoc(chatRef, newChat);
      }
      const messagesRef = collection(chatRef, 'messages');
      const newMessage: Message = {
        id: '',
        content: content,
        sender: sender,
        receiver: receiver,
        timestamp: Timestamp.now(),
        readStatus: false,
        senderType: SenderType.CUSTOMER
      };
      const messageDocRef = await addDoc(messagesRef, newMessage);
      const latestMessageUpdate: Partial<Chat> = {
        id: chatId as string,
        latestMessage: {
          content: newMessage.content,
          timestamp: newMessage.timestamp,
          sender: sender
        }
      };
      await updateDoc(chatRef, latestMessageUpdate);

      const messageIdUpdate: Partial<Message> = {
        id: messageDocRef.id
      };

      await updateDoc(messageDocRef, messageIdUpdate);
    } catch (error) {
      throw error;
    }
  }

  getMessagesByChat(
    locationId: string | undefined,
    chatId: string,
    currentLoggedUser: User,
    limitSize: number,
    oldestDoc: any = null
  ): Observable<{ messages: Message[], nextOldestDoc: any }> {

    return new Observable((observer) => {
      if (!locationId || !chatId) {
        observer.error(new Error('Location ID or Chat ID is undefined'));
        return;
      }

      const messagesRef = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);

      let q;
      if (oldestDoc) {
        q = query(messagesRef, orderBy('timestamp', 'asc'), endBefore(oldestDoc), limitToLast(limitSize)
        );
      } else {
        q = query(messagesRef, orderBy('timestamp', 'asc'), limitToLast(limitSize));
      }

      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          /*if (snapshot.empty) {
            observer.complete();
            return;
          }*/
          const messagesList: Message[] = [];

          snapshot.forEach((doc) => {
            const message = doc.data() as Message;
            message.id = doc.id;

            if (!message.readStatus && message.senderType === SenderType.LOCATION) {
              this.markMessageAsRead(locationId, chatId, message.id).catch((error) => {
              });
            }

            messagesList.push(message);
          });

          const nextOldestDoc = snapshot.docs[0];
          observer.next({messages: messagesList, nextOldestDoc});
        },
        (error) => {
          observer.error(error);
        }
      );

      return () => unsubscribe();
    });
  }

  getChatsByLocation(locationId: string): Observable<Chat[]> {
    return new Observable((observer) => {
      const chatsRef = collection(this.firestore, `locations/${locationId}/chats`);
      const q = query(chatsRef, orderBy('createdAt', 'desc'));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const chatList: Chat[] = [];

        const promises = snapshot.docs.map(async (doc) => {
          const chat = doc.data() as Chat;
          chat.id = doc.id;

          const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${doc.id}/messages`);
          const unreadQuery = query(unreadMessagesRef, where('readStatus', '==', false));
          const unreadSnapshot = await getDocs(unreadQuery);
          chat.unreadCount = unreadSnapshot.size;
          chatList.push(chat);
        });
        await Promise.all(promises);
        observer.next(chatList);
      });
      return () => unsubscribe();
    });
  }

  getChatByLocationAndId(locationId: string, chatId: string): Observable<Chat> {
    return new Observable((observer) => {
      const chatRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}`);
      const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);

      const unsubscribe = onSnapshot(chatRef, async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const chat = docSnapshot.data() as Chat;
          chat.id = docSnapshot.id;

          const unreadQuery = query(unreadMessagesRef, where('readStatus', '==', false),
            where('sender.id', '==', chatId));
          const unreadSnapshot = await getDocs(unreadQuery);

          chat.unreadCount = unreadSnapshot.size;
          observer.next(chat);
        } else {
          observer.next(undefined);
        }
      });
      return () => unsubscribe();
    });
  }


  async markMessageAsRead(locationId: string | undefined, chatId: string, messageId: string): Promise<void> {
    const messageRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}/messages/${messageId}`);
    try {
      await updateDoc(messageRef, {readStatus: true});
    } catch (error) {
    }
  }

  getLocationsByChatId(chatId: string): Observable<Chat[]> {

    const fetchChats = async (): Promise<string[]> => {
      const locationsRef = collection(this.firestore, 'locations');
      const locationSnapshots = await getDocs(locationsRef);
      return locationSnapshots.docs.map(doc => doc.id);
    };

    return from(fetchChats()).pipe(
      mergeMap((locationIds) => {

        const chatObservables = locationIds.map(locationId =>
          this.getChatsByLocation(locationId).pipe(
            map(chats =>
              chats.filter(chat => chat.id === chatId).map(chat => ({
                ...chat,
                locationId
              }))
            )
          )
        );

        return forkJoin(chatObservables).pipe(
          map((chatsArray) => chatsArray.flat())
        );
      })
    );
  }

  getChatsAndMessagesByChatId(chatId: string): Observable<{ chat: Chat, messages: Message[] }[]> {
    const fetchChats = async (): Promise<string[]> => {
      const locationsRef = collection(this.firestore, 'locations');
      const locationSnapshots = await getDocs(locationsRef);

      return locationSnapshots.docs.map(doc => doc.id);
    };

    return from(fetchChats()).pipe(
      mergeMap((locationIds) => {
        const chatObservables = locationIds.map(locationId =>
          this.getChatAndMessages(locationId, chatId)
        );

        return forkJoin(chatObservables).pipe(
          map((chatsArray) => chatsArray.filter(chatData => chatData !== null) as { chat: Chat, messages: Message[] }[])
        );
      })
    );
  }

  getChatsByChatIdAcrossLocations(chatId: string): Observable<Chat[]> {
    return new Observable((observer) => {
      const chatsQuery = query(
        collectionGroup(this.firestore, 'chats'),
        where('id', '==', chatId),
        orderBy('latestMessage.timestamp', 'desc')
      );

      const unsubscribe = onSnapshot(chatsQuery, async (snapshot) => {
        const chatList: Chat[] = [];

        const promises = snapshot.docs.map(async (doc) => {
          const chat = doc.data() as Chat;
          chat.id = doc.id;

          const locationRef = doc.ref.parent.parent;
          if (locationRef) {
            const locationId = locationRef.id;

            const unreadMessagesRef = collection(this.firestore, `locations/${locationId}/chats/${chat.id}/messages`);
            const unreadQuery = query(unreadMessagesRef,
              where('readStatus', '==', false),
              where('senderType', '==', SenderType.LOCATION));
            const unreadSnapshot = await getDocs(unreadQuery);
            chat.unreadCount = unreadSnapshot.size;
          }
          chatList.push(chat);
        });

        await Promise.all(promises);

        observer.next(chatList);
      });

      return () => unsubscribe();
    });

  }

  getChatAndMessages(locationId: string, chatId: string): Observable<{ chat: Chat, messages: Message[] } | null> {
    return new Observable((observer) => {
      const chatDocRef = doc(this.firestore, `locations/${locationId}/chats/${chatId}`);
      getDoc(chatDocRef).then((chatSnapshot) => {
        if (!chatSnapshot.exists()) {
          observer.next(null);
          observer.complete();
          return;
        }

        const chat = chatSnapshot.data() as Chat;
        chat.id = chatId;

        const messagesRef = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);
        getDocs(messagesRef).then((messagesSnapshot) => {
          const messages: Message[] = messagesSnapshot.docs.map(doc => {
            const message = doc.data() as Message;
            message.id = doc.id;
            return message;
          });

          observer.next({chat, messages});
          observer.complete();
        }).catch((error) => {
          observer.error(error);
        });
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  async searchMessages(searchTerm: string): Promise<Message[]> {
    const messagesCollection = collection(this.firestore, 'messages');
    const messagesQuery = query(messagesCollection, where('readStatus', '==', true));
    const querySnapshot = await getDocs(messagesQuery);
    const foundMessages: Message[] = [];

    querySnapshot.forEach((doc) => {
      foundMessages.push(doc.data() as Message);
    });
    return foundMessages;
  }

  async searchChatsByUserName(chatId: string, searchTerm: string): Promise<Chat[]> {
    const chatsCollection = collectionGroup(this.firestore, `chats`);

    /*   const chatsQuery = query(chatsCollection, where('chatUser.name', '>=', searchTerm),
         where('chatUser.name', '<=', searchTerm + '\uf8ff'));*/

    const chatQuery = query(
      chatsCollection,
      where('id', '==', chatId),
      where('location.firstName', '>=', searchTerm),
      where('location.firstName', '<=', searchTerm + '\uf8ff')
    );

    const querySnapshot = await getDocs(chatQuery);
    const foundChats: Chat[] = [];

    querySnapshot.forEach((doc) => {
      const chatData = doc.data();
      const additionalProp = {unreadCount: 0};
      const combinedData = {...chatData, ...additionalProp};
      foundChats.push(combinedData as Chat);
    });

    return foundChats;
  }

  async searchMessagesInChat(chatId: string, locationId: string, searchTerm: string): Promise<Message[]> {
    const messagesCollection = collection(this.firestore, `locations/${locationId}/chats/${chatId}/messages`);
    const messagesQuery = query(messagesCollection, where('content', '>=', searchTerm),
      where('content', '<=', searchTerm + '\uf8ff'));

    const querySnapshot = await getDocs(messagesQuery);
    const foundMessages: Message[] = [];

    querySnapshot.forEach((doc) => {
      foundMessages.push(doc.data() as Message);
    });
    return foundMessages;
  }

}

