<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Chat Bot</title>

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
        rel="stylesheet">


  <link crossorigin="anonymous" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" rel="stylesheet">

  <script crossorigin="anonymous"
          integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p"
          src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>
  <script crossorigin="anonymous"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
</head>
<body>


<section class="message-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="chat-area">
          <!-- chatlist -->
          <div class="chatlist">
            <div class="modal-dialog-scrollable">
              <div class="modal-content">
                <div class="chat-header">
                  <div class="msg-search">
                    <input (keyup)="onChatSearch()" (keyup.enter)="onChatSearch()" [(ngModel)]="searchChatTerm"
                           aria-label="search" class="form-control mb-1"
                           id="inlineFormInputGroup" placeholder="Search Chats By Location Name"
                           type="text">

                  </div>

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">

                      <div class="form-group">
                        <select (change)="onLocationChange()" [(ngModel)]="selectedLocationId"
                                class="form-control mb-1" id="locationSelect">
                          <option disabled selected value="">Select Location</option>
                          <option *ngFor="let location of locations"
                                  [value]="location.locationId">{{ location.locationName }}</option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="modal-body">
                  <!-- chat-list -->
                  <div class="chat-lists">
                    <div class="tab-content" id="myTabContent">
                      <div aria-labelledby="Open-tab" class="tab-pane fade show active" id="Open" role="tabpanel">
                        <!-- chat-list -->
                        <div class="chat-list ">
                          <div *ngIf="chatsLoading" class="loading-spinner">
                            <img alt="Loading" src="../../../assets/icons/loading.gif">
                          </div>

                          <div *ngIf="chatAvailable" class="loading-spinner">
                            <p>No Chats Available.</p>
                          </div>

                          <div *ngIf="foundChats.length > 0" >
                            <a (click)="selectChat(chat.id , chat.location.id)" *ngFor="let chat of foundChats"
                               class="d-flex align-items-center " style="cursor: pointer"
                               [ngClass]="{'chat-list-active': chat.id === chat.id}"
                            >
                              <div class="flex-shrink-0">
                                <img [src]="chat?.location?.avatar"
                                     alt="user img"
                                     class="img-fluid">
                                <span class="active"></span>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                <h3>{{ chat?.location?.firstName + '  ' + chat?.location?.lastName }}</h3>
                                <p>{{ chat?.latestMessage?.content | truncateMessage: wordLimit }}</p>
                                <p *ngIf="chat?.unreadCount !== 0 && chat.id == this.currentLoggedUser.id"
                                   class="count">{{ chat?.unreadCount }}</p>
                              </div>
                            </a>
                          </div>
                          <div *ngIf="foundChats.length == 0">
                            <a (click)="selectChat(chat.id , chat.location.id)" *ngFor="let chat of chats"
                               class="d-flex align-items-center" style="cursor: pointer">
                              <div class="flex-shrink-0">
                                <img [src]="chat?.location?.avatar"
                                     alt="user img"
                                     class="img-fluid">
                                <span class="active"></span>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                <h3>{{ chat?.location?.firstName + ' ' + chat?.location?.lastName }}</h3>
                                <p>{{ chat?.latestMessage?.content | truncateMessage: wordLimit }}</p>
                                <p *ngIf="chat?.unreadCount !== 0 && chat.id == this.currentLoggedUser.id"
                                   class="count">{{ chat?.unreadCount }}</p>
                              </div>
                            </a>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="chatbox">
            <div class="modal-dialog-scrollable">
              <div class="modal-content">
                <div class="msg-head">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex align-items-center">
                        <span class="chat-icon">
                          <img alt="image title" class="img-fluid" src=""></span>

                        <div *ngIf="chatUserLoading" class="loading-spinner">
                          <img alt="Loading" src="../../../assets/icons/loading.gif">
                        </div>

                        <div *ngIf="selectedChatUser" class="flex-shrink-0">
                          <img [src]="selectedChatUser?.avatar" alt="user img"
                               class="img-fluid">
                        </div>

                        <div *ngIf="selectedChatUser" class="flex-grow-1 ms-3">
                          <h3>{{ selectedChatUser?.name }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 mt-2">
                      <div class="msg-search">
                        <input (keyup)="onMessageSearch()" (keyup.enter)="onMessageSearch()"
                               [(ngModel)]="searchMessageTerm"
                               aria-label="search" class="form-control"
                               placeholder="Search Messages"
                               type="text">
                        <button (click)="logout()" class="default-btn">Logout</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div #messageContainer (scroll)="onScroll($event)" class="modal-body">
                  <div class="msg-body">

                    <div *ngIf="loadingMoreMessages || messagesLoading" class="loading-spinner">
                      <img alt="Loading" src="../assets/icons/loading.gif">
                    </div>

                    <ul>
                      <div #messageElement>
                        <li *ngFor="let message of messages"
                            [ngClass]="message.senderType === senderType.CUSTOMER ? 'repaly' : 'sender'">
                          <p>{{ message.content }}</p>
                          <span class="time">{{ message.timestamp | dateDisplay }}
                            <svg [attr.fill]="message.readStatus ? '#4b7bec' : 'grey'"
                                 height="15" viewBox="0 0 512 512" width="15"
                                 xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M505 174.8l-39.6-39.6c-9.4-9.4-24.6-9.4-33.9 0L192 374.7 80.6 263.2c-9.4-9.4-24.6-9.4-33.9 0L7 302.9c-9.4 9.4-9.4 24.6 0 34L175 505c9.4 9.4 24.6 9.4 33.9 0l296-296.2c9.4-9.5 9.4-24.7 .1-34zm-324.3 106c6.2 6.3 16.4 6.3 22.6 0l208-208.2c6.2-6.3 6.2-16.4 0-22.6L366.1 4.7c-6.2-6.3-16.4-6.3-22.6 0L192 156.2l-55.4-55.5c-6.2-6.3-16.4-6.3-22.6 0L68.7 146c-6.2 6.3-6.2 16.4 0 22.6l112 112.2z"/>
                          </svg>
                          <span
                            class="location_name">By: {{ message.sender.firstName + ' ' + message.sender.lastName}}</span>

                        </span>

                        </li>
                      </div>
                    </ul>
                  </div>
                </div>

                <div class="send-box">
                  <form (submit)="sendMessage()">
                    <input [(ngModel)]="newMessage" aria-label="message…" class="form-control"
                           name="message" placeholder="Write message…" type="text">
                    <button [disabled]="!sendMessageBtnEnable" type="submit"><i aria-hidden="true"
                                                                                class="fa fa-paper-plane"></i> Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</body>
</html>
