<div class="login-container">
  <div class="login-left">
    <h2>Login Vitality 👋</h2>
    <form (ngSubmit)="onLogin()">
      <label for="email">Email Or Mobile</label>
      <input type="email" id="email" [(ngModel)]="email" name="email" placeholder="xxx@mailxchge.com Or XXXXXXXXXXX" required>
      <label for="password">Password</label>
      <input type="password" id="password" [(ngModel)]="password" name="password" placeholder="******" required>
      <button type="submit" class="btn btn-signin">Sign In</button>
    </form>
  </div>
  <div class="login-right">
    <div class="login-info">
      <img src="assets/dashboard/ai-generated-8845752_640.webp" alt="Dashboard example">
    </div>
  </div>
</div>
