import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncateMessage'
})
export class TruncateMessagePipe implements PipeTransform {

  transform(message: string | undefined, wordLimit: number): string {
    if (!message || wordLimit <= 0) {
      return '';
    }
    const words = message.split(' ');
    if (words.length <= wordLimit) {
      return message;
    }
    const validWords = words.slice(0, wordLimit);
    if (!validWords[wordLimit - 1].match(/^[a-zA-Z0-9]+$/)) {
      return validWords.slice(0, wordLimit - 1).join(' ') + '...';
    }
    return validWords.join(' ') + '...';
  }

}
