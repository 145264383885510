

export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyDPs3li1nsPfEngYu3cseAkw1PJM3E0yWE",
    authDomain: "alpha-wemakeplatforms.firebaseapp.com",
    projectId: "alpha-wemakeplatforms",
    storageBucket: "alpha-wemakeplatforms.appspot.com",
    messagingSenderId: "141705325816",
    appId: "1:141705325816:web:e2127a7a607802dc945f0d"
  },
  client: 'wellness',
  secret: 'w2ellness',
};
