import { Injectable } from '@angular/core';
import {LocationSummary} from "../model/models";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private _url:string =  '/dashboard-gateway/companies';

  constructor(protected httpClient: HttpClient){ }

  findAllLocationsByCompanyId(companyId?: string, searchText?: string, page?: number, size?: number, sort?: string): Observable<LocationSummary> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.httpClient.get<LocationSummary>(`${this._url}/` + companyId + '/locations' + ((searchText || page || size || sort) ? '?' : '') + (searchText ? 'searchText=' + encodeURIComponent(searchText) + '&' : '') + (page ? 'page=' + page + '&' : '') + (size ? 'size=' + size + '&' : '') + (sort ? 'sort=' + sort + '&' : '')+ (timeZone ? 'timeZone=' + encodeURIComponent(timeZone) : ''));
  }
}
