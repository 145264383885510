import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {environment} from "../../environments/environment";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenUrl = '/dashboard-gateway/oauth2/token';

  constructor(private http: HttpClient,
              private jwtHelperService: JwtHelperService) {}

  login(email: string, password: string): Observable<any> {
    const authCredentials = btoa(environment.client + ':' + environment.secret);
    const headers = new HttpHeaders({'authorization':`Basic ${authCredentials}` ,'Content-Type': 'application/x-www-form-urlencoded' });
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('username', email);
    urlSearchParams.append('password', password);
    urlSearchParams.append('grant_type', 'custom_password');
    urlSearchParams.append('scope', 'openid, profile');
    urlSearchParams.append('client_type', 'customer-app');
    return this.http.post<any>(this.tokenUrl, urlSearchParams.toString(), { headers: headers }).pipe(
      tap(response => {
        if (response && response.access_token) {
          localStorage.setItem('access_token', response.access_token);
          localStorage.setItem('refresh_token', response.refresh_token);
        }
      })
    );
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  loadToken() : any{
    return this.jwtHelperService.decodeToken();
  }

}
