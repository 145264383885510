import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Chat, Location, Message, Page, SenderType, User} from "../../model/models";
import {Subscription} from "rxjs";
import {ChatServiceService} from "../../service/chat-service.service";
import {Timestamp} from "@angular/fire/firestore";
import {AuthService} from "../../service/auth.service";
import {Router} from "@angular/router";
import {LocationService} from "../../service/location.service";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
  chats: Chat[] = [];
  messages: Message[] = [];
  selectedChatUser: any;
  currentLoggedUser!: User;
  newMessage: string = '';
  selectChatId: string = '';
  loadingMoreMessages: boolean = false;
  loadingNewMessages: boolean = false;
  hasMoreMessages: boolean = true;
  messagesPageSize: number = 6;
  selectedChatId: string = '';
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  @ViewChildren('messageElement') messageElements!: QueryList<ElementRef>;
  searchChatTerm: string = '';
  searchMessageTerm: string = '';
  foundChats: Chat[] = [];
  foundMessages: Message[] = [];

  locations: any[] = [];
  selectedLocationId: string = '';
  previousSelectedLocationId: string = ''
  selectedLocation!: Location;
  locationId: string = '';

  page = new Page();
  sort: string = "createdDate,desc";
  searchText!: string;
  companyId?: string;
  oldestDoc: any = null;
  sendMessageBtnEnable: boolean = true;
  chatsLoading: boolean = true;
  messagesLoading: boolean = true;
  chatUserLoading: boolean = true;
  chatAvailable!: boolean;
  unreadCountAvailable!: boolean;
  senderType = SenderType;
  wordLimit: number = 4;

  private getMessageByChatSubscription: Subscription | undefined;
  private getMessagesByChatSubscription: Subscription | undefined;
  private getCustomerSubscription: Subscription | undefined;
  private getChatByLocationAndIdSubscription: Subscription | undefined;
  private getChatByLocationSubscription: Subscription | undefined;
  private readonly token: any;
  private avatarURL: string = '../assets/icons/staff.svg';

  constructor(private chatService: ChatServiceService,
              private authService: AuthService,
              private locationService: LocationService,
              private router: Router) {
    this.token = this.authService.loadToken();
    this.loadCurrentCompanyId();
    this.loadCurrentLoggedUser();
  }

  ngOnInit() {
    this.fetchLocationsTable();
  }

  loadInitialChats() {
    this.chatService.getChatsByChatIdAcrossLocations(this.currentLoggedUser.id).subscribe(locationChats => {
      if (locationChats.length > 0) {
        if (this.selectedChatId !== locationChats[0].id) {
          this.selectChat(locationChats[0].id, locationChats[0].location.id);
        }
        this.selectedLocation = {
          locationId: locationChats[0].location.id,
          locationName: locationChats[0].location.firstName + ' ' + locationChats[0].location.lastName,
          chats: []
        }
        this.chatsLoading = false;
        this.chats = locationChats;

      } else {
        this.chatsLoading = false;
        this.chatUserLoading = false;
        this.messagesLoading = false;
        this.chatAvailable = true
      }
    });

  }

  selectChat(chatId: string, locationId: string): void {

    this.getMessageByChatSubscription?.unsubscribe();
    this.chatUserLoading = true;
    this.messagesLoading = true;

    const chatToSelect = this.chats.find(chat => chat.location.id === locationId);

    if (chatToSelect) {
      this.selectedChatId = chatToSelect.id;
    } else {
      this.selectedChatId = chatId;
    }

    this.selectedLocation = this.locations.find(location => location.locationId === locationId);

    this.selectedChatUser = {
      id: this.selectedLocation?.locationId,
      name: this.selectedLocation?.locationName,
      avatar: this.avatarURL
    };

    if (this.selectedChatUser) this.chatUserLoading = false;

    this.loadChatMessages(locationId, chatId);
  }

  loadChatMessages(locationId: string, chatId: string): void {

    this.getMessageByChatSubscription = this.chatService.getMessagesByChat(locationId, this.selectedChatId, this.currentLoggedUser, this.messagesPageSize)
      .subscribe({
        next: ({messages, nextOldestDoc}) => {
          if (messages.length > 0) {
            this.messages = messages;
            this.scrollToBottom();
            this.oldestDoc = nextOldestDoc;
            this.hasMoreMessages = messages.length >= this.messagesPageSize;
            this.messagesLoading = false;
            this.chatAvailable = false;
          } else {
            this.hasMoreMessages = false;
            this.messagesLoading = false;
            this.initializeNewChat(chatId);
            this.chatAvailable = false;
          }
          this.loadingMoreMessages = false;
        },
        error: (error) => {
          this.loadingMoreMessages = false;
        },
        complete: () => {
          this.hasMoreMessages = false;
          this.loadingMoreMessages = false;
        }
      });
  }

  initializeNewChat(chatId: string): void {
    this.messages = [];
    const newChat = {
      id: chatId as any,
      location: {
        id: this.selectedLocationId,
        firstName: this.selectedLocation.locationName,
        lastName: '',
        avatar: this.avatarURL
      },
      chatUser: {
        id: this.currentLoggedUser.id,
        firstName: this.currentLoggedUser.firstName,
        lastName: this.currentLoggedUser.lastName,
        avatar: this.avatarURL
      },
      latestMessage: null as any,
      createdAt: Timestamp.now(),
      unreadCount: 0
    };
    this.selectedChatUser = {
      id: this.selectedLocation.locationId,
      name: this.selectedLocation.locationName,
      avatar: this.avatarURL
    };
    if (!this.chats.some(chat => chat.location.id === this.selectedLocationId)) {
      this.chats = [newChat, ...this.chats];
    }
  }


  onLocationChange() {
    this.selectChat(this.currentLoggedUser.id, this.selectedLocationId);

  }


  onScroll(event: any) {
    const target = event.target;
    if (target.scrollTop === 0 && !this.loadingMoreMessages) {
      this.loadMoreMessages();
    }
  }

  loadMoreMessages() {

    if (this.loadingMoreMessages || !this.hasMoreMessages) return;

    this.loadingMoreMessages = true;
    this.getMessagesByChatSubscription = this.chatService
      .getMessagesByChat(this.selectedLocation.locationId,
        this.selectedChatId,
        this.currentLoggedUser,
        this.messagesPageSize,
        this.oldestDoc)
      .subscribe({
        next: ({messages, nextOldestDoc}) => {
          if (messages.length > 0) {
            this.messages = [...messages, ...this.messages];
            this.oldestDoc = nextOldestDoc;

            if (messages.length < this.messagesPageSize) {
              this.hasMoreMessages = false;
            }
          } else {
            this.hasMoreMessages = false;
          }

          this.loadingMoreMessages = false;
        },
        error: (error) => {
          this.loadingMoreMessages = false;
        },
        complete: () => {
          this.hasMoreMessages = false;
          this.loadingMoreMessages = false;
        }
      });

  }


  sendMessage() {
    if (this.newMessage.trim() === "")
      return;
    let newMessage = this.newMessage;
    this.newMessage = '';
    this.sendMessageBtnEnable = false;
    this.chatService.sendMessage(this.selectedLocation.locationId, this.currentLoggedUser.id, newMessage, this.currentLoggedUser, this.selectedChatUser, this.selectedLocation)
      .then(() => {
        newMessage = '';
        this.newMessage = '';
        this.sendMessageBtnEnable = true;
      }).catch(() => {
    });
  }


  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
    }
  }

  async onChatSearch(): Promise<void> {
    if (this.searchChatTerm.trim()) {
      this.chats = await this.chatService.searchChatsByUserName(this.currentLoggedUser.id, this.searchChatTerm);
      this.messages = [];
      if (this.chats.length > 0) {
        this.selectChat(this.chats[0].id, this.chats[0].location.id);
        // this.locationId = this.selectedLocation.locationId;
      }
    } else {
      this.loadInitialChats();
    }
  }

  async onMessageSearch(): Promise<void> {
    if (this.searchMessageTerm.trim()) {
      const foundMessages = await this.chatService.searchMessagesInChat(this.selectedChatId, this.selectedLocation.locationId, this.searchMessageTerm);
      if (foundMessages.length > 0) {
        this.messages = foundMessages;
        this.scrollToMessage(this.messages[0].id);
      }
    } else {
      this.selectChat(this.selectedChatId, this.selectedLocation.locationId);
    }
  }

  scrollToMessage(messageId: string): void {
    const targetMessage = this.messageElements.find(
      (message) => message.nativeElement.id === messageId
    );

    if (targetMessage) {
      targetMessage.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      targetMessage.nativeElement.classList.add('highlighted');
      setTimeout(() => {
        targetMessage.nativeElement.classList.remove('highlighted');
      }, 2000);
    }
  }


  ngOnDestroy(): void {
    this.getMessageByChatSubscription?.unsubscribe();
    this.getMessagesByChatSubscription?.unsubscribe();
    this.getCustomerSubscription?.unsubscribe();
    this.getChatByLocationAndIdSubscription?.unsubscribe();
    this.getChatByLocationSubscription?.unsubscribe();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  fetchLocationsTable() {
    this.chatsLoading = true;
    this.locationService.findAllLocationsByCompanyId(
      this.companyId,
      this.searchText,
      this.page.number,
      this.page.size,
      this.sort
    ).subscribe({
      next: (data: any) => {
        this.page = data?.page;
        this.page.totalPages = data?._embedded != undefined ? this.page.totalPages : 0;
        this.locations = this.getLocationDetails(data?._embedded?.locationSummaryDtoes);
        if (this.locations) {
          this.loadInitialChats();
        }
      },
      error: () => {
      }
    });
  }

  loadCurrentCompanyId() {
    if (this.token) {
      this.companyId = this.token.companyId;
    }
  }

  getLocationDetails(array: any[]): any[] {
    return array.map(item => ({
      locationId: item.id,
      locationName: item.locationName
    }));
  }

  loadCurrentLoggedUser() {
    this.currentLoggedUser = {
      id: this.token.customerId,
      firstName: this.token.firstName,
      lastName: this.token.lastName,
      avatar: this.avatarURL
    }
  }

}
