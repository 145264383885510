import {Component} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email: string = '';
  password: string = '';

  constructor(private authService: AuthService, private router: Router,
              private notifierService: NotifierService) {
    this.authService.logout();
  }

  onLogin() {
    this.authService.login(this.email, this.password).subscribe(
      response => {
        if (response) {
          this.router.navigate(['/chat']);
        }
      },
      error => {
        this.notifierService.notify('error', 'Please Enter Valid Credentials!');
      }
    );
  }
}
